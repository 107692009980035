import {Col, Row} from 'react-bootstrap'
import React, {FC} from 'react'
import {ChildData} from '../helpers/ChildData'
export function manageVehicle(combination: any) {
  let response: any = {}
  if (combination && combination.length) {
    for (const combinationElement of combination) {
      if (response.hasOwnProperty(combinationElement.name)) {
        response[combinationElement.name] = response[combinationElement.name] + 1
      } else {
        response[combinationElement.name] = 1
      }
    }
    return Object.keys(response).map((key) => [key, response[key]])
  } else {
    return []
  }
}
export const Private: FC<{sightSeeing: any}> = ({sightSeeing}) => {
  return (
    <>
      <Row className='px-2 mt-4'>
        <Col sm={12}>
          <table className='w-100'>
            <thead>
            <tr className='lightPinkBackground text-white'>
              <th>Service Includes</th>
              <th>Vehicle</th>
              <th>Adults</th>
              <th>Child(s)</th>
              <th>Infant(s)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{sightSeeing?.sight_seeing?.parameters?.private_inclusions && <span
                dangerouslySetInnerHTML={{__html: sightSeeing?.sight_seeing?.parameters?.private_inclusions}} />}</td>
              <td>
                {(() => {
                  const responseData = manageVehicle(sightSeeing.combination)
                  return responseData.map((value: any) => (
                    <span>{value[1]} * {value[0]}</span>
                  ))
                })()}
              </td>
              <td>{sightSeeing.adults.length}</td>
              <td><ChildData children={sightSeeing.children} /></td>
              <td>{sightSeeing.children.filter((child: {age: number}) => child.age <= 2).length}</td>
            </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className='px-2 mt-4'>
        <Col sm={12}>
          <table className='w-100'>
            <thead>
            <tr className='lightPinkBackground text-white'>
              <th className='text-center'>Service Exclude</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{sightSeeing?.sight_seeing?.parameters?.private_exclusions && <span
                  dangerouslySetInnerHTML={{__html: sightSeeing?.sight_seeing?.parameters?.private_exclusions}} />}</td>
            </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className='px-2 mt-4'>
        <Col sm={12}>
          <table className='w-100'>
            <thead>
            <tr className='lightPinkBackground text-white'>
              <th className='text-center'>Pickup Point</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <ul>
                  <li>{sightSeeing?.sight_seeing?.parameters?.private_pickup_area}</li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className='px-2 mt-4'>
        <Col sm={12}>
          <table className='w-100'>
            <thead>
            <tr className='lightPinkBackground text-white'>
              <th className='text-center'>Emergency Contact No.</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                  <ul>
                    <li>+66 84 236 0681 (Hindi, English Speaking)</li>
                  </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className='px-2 mt-4'>
        <Col>
          <strong>Remarks:</strong>
          <br />
          <strong>IMPORTANT NOTE:</strong> <span className='text-danger'>**</span>The tour rates and availability are
          subject to change without prior notice based on COVID regulations and guidelines imposed by the Thai
          Government. Rates available or Service Booked are indicative for reference and subject to change.
        </Col>
      </Row>
      <Row className='px-2 mt-4'>
        <Col>
          <strong>Terms & Conditions</strong>
          <ul>
            <li>The actual pick-up time to be reconfirmed before 1 day before service date.</li>
            <li>Maximum waiting time is 05 mins for SIC and 15 mins for Private, from the time Guide/ Driver have
              reported at the hotel for Pick-up. Guide will look for the guest in the Lobby Concierge area and if guest
              not found will call the hotel room. If no answer is received from room, the guide / driver will leave the
              premises and it will be considered a No Show.
            </li>
            <li>Guest who fails to avail the transfer service will have to make their own arrangement for transfers /
              tours. No Refund or Reschedule is applicable
            </li>
            <li>For Transfers & Tours: Guests are requested to be at the place of Pick-up / hotel lobby 05 minutes
              before the pickup time as mentioned in itinerary and may have to wait for a period of about 30 -45 minutes
              - depending upon - traffic, location of the hotel on the route.
            </li>
            <li>Any guests not found at the time of vehicle arrival would be considered NO SHOW and no refund or
              reinstating of their tour on another day or time would be considered
            </li>
            <li>No child below 35 Inch will be admitted to attraction unless accompanied by an adult. If found Above the
              mentioned height, then the service provider has right to charge the supplement from guest on ground as per
              adult cost
            </li>
            <li>If Infant age found more than 2 years, Guest must buy his/her tickets by their own at Ticket Counter.
            </li>
            <li>No refund applicable should the guest not travel or visit the attraction</li>
            <li>The tour should be completed within 3-4 hours for H/D and 6-8 hours for F/D including pick-up and
              drop-off from hotel. Any delays caused by the guest may attract surcharge or No Show.
            </li>
            <li>All schedules are subject to change due to traffic, weather conditions and number of guests during peak
              times or any other unforeseen reasons.
            </li>
            <li>Any changes requested due to unavoidable circumstances must be made strictly with the Duty manager and
              would remain subject to availability and conditions of the Tour. Messages left with Guides and Drivers for
              changes will not be considered valid.]
            </li>
          </ul>
          <strong>Special Note:</strong>
          <ul>
            <li>There is no Transport Service available between Island Pier - Hotel, Koh Phi Phi Island | Koh Pha Ngan |
              Ko Tao | & more… *For Details please contact our Operation Team
            </li>
            <li>Guests staying at Railay Beach : Pick up/Drop at the East Side of Long Bridge : 0900 hrs.</li>
            <li>Guest staying at Centara Grand Beach Resort: Pick up/Drop from Nopparat Thara Beach Pier : 0815 - 08:30
              hrs.
            </li>
            <li>Guest staying at Coconut Island: Pick up/Drop from Laem Hin Pier.</li>
          </ul>
          <strong>Phuket Zone wise Areas:</strong>
          <ul>
            <li>Zone 1: Patong Beach, Kata Beach, Karon Beach, Phuket Town</li>
            <li>Zone 2: Kamala Beach Area</li>
            <li>Zone 3: NaiHarn, Rawai, Panwa Beach, Surin, Bang Tao, Laguna</li>
            <li>Zone 4: Naithon, Mai Khao</li>
          </ul>
          <strong>Krabi Zone wise Areas:</strong>
          <ul>
            <li>Zone 1: Ao Nang</li>
            <li>Zone 2: Kwang, Klong Muang, Tubkaek</li>
          </ul>
          <strong>Bangkok Zone wise Areas:</strong>
          <ul>
            <li>Zone 1: Pratunam, Makkasan, Silom, Sathorn, Siam, Sukhumvit Soi 3-28</li>
          </ul>
          <strong>Pattaya Zone wise Areas:</strong>
          <ul>
            <li>Zone 1: Pattaya City Centre Area, North Pattaya, South Pattaya</li>
            <li>Zone 2: Jomtien</li>
          </ul>
        </Col>
      </Row>
    </>
  )
}